import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Image } from 'semantic-ui-react';
import ggbs from '../assets/images/logos/ggbs.svg'


const GreenPolicyPage = (params) => {
  return (
    <Layout {...params}>
			<div className="greenpolicy page">

				<SEO title={`Green Policy`} />
				<section className="content description" id="ggbspolicy">
					<div className="mark">
						<Image src={ggbs} />
						{/* <h1>Green &amp; Gracious Policy</h1> */}
						<h2>Commitment</h2>
						<p>The Top management and staff are fully committed to implement the Green &amp; Gracious activities and practices to identify and minimise the adverse environmental impact, occupational and health risks associated with our building construction works and activities. We are committed to continually improve our Environmental, Health &amp; Safety Management system.</p>
						<h2>Environment</h2>
						<p>Be aware of the environmental impact of our site and minimize the effects of noise, water and air pollution. Efforts will be made to promote and practice waste management, energy, fuel and water conservation. Reduce, reuse and recycle construction materials and office stationaries whenever possible.</p>
						<h2>Cleanliness</h2>
						<p>To implement and maintain a clean environment at work area including areas affecting the public, site facilities, which include the site office, workers quarters, toilets and site canteen. Project site shall observe good housekeeping habit.</p>
						<h2>Considerate</h2>
						<p>We pledge to carry out our social responsibility in protecting the interest of our employees, customers and members of the public. General information of the project will be disseminated to neighbours or general public. Regular communication is made available to public until project completion.</p>
						<h2>Responsibility</h2>
						<p>Ensure everyone associated with the site understands, implements and compiles with environmental, health &amp; safety awareness and practices.</p>
					</div>
				</section>
			</div>
    </Layout>
  )
}

export default GreenPolicyPage;